<template>
  <div class="h-full cust_card p-2 mt-2" style="overflow: visible !important;">
    <div class="heading-3 text-text1">● Email Configuration:</div>
    <div class="bottom_space rounded py-2 h-full pt-4 bg-white">
      <div>
        <!-- <div style="overflow-y: auto; white-space: nowrap;" class="table_containder" v-for="(data, index) in organizationList" :key="index">
          <div  class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-4 " >
            <div class="card rounded-lg bg-white p-4 m-4">
              {{ data.emailId }}
            </div>
          </div>
        </div> -->
        <div class="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2">
          <div class="card rounded-lg bg-white p-2 m-2 relative cursor-pointer hover:bg-gray-50 transition group" v-for="(data, index) in organizationList" :key="index" @click="redirectToDetail(data)">
            <div class="absolute right-2 top-2">
              <span v-if="data.isActive"><i class="fa-solid fa-circle text-success heading-6"></i></span>
              <p v-if="!data.isActive"><i class="fa-solid fa-circle text-error heading-6"></i></p>
            </div>
            <div class="p-1">
              <p class="mb-0 items-center flex">
                <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:170px;">Label</p></span>
                <span><p class="mb-0 dotwidth">:</p></span>
                <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="data.emailLabel !== ''">{{data.emailLabel}}</span>
                <span class="text-text1 heading-5" v-else>---</span>
              </p>
            </div>
            <div class="p-1">
              <p class="mb-0 items-center flex">
                <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:170px">Email</p></span>
                <span><p class="mb-0 dotwidth">:</p></span>
                <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="data.emailId !== ''">{{data.emailId}}</span>
                <span class="text-text1 heading-5" v-else>---</span>
              </p>
            </div>
            <div class="p-1">
              <p class="mb-0 items-center flex">
                <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:170px">Password</p></span>
                <span><p class="mb-0 dotwidth">:</p></span>
                <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="data.passwordEnc !== ''">******</span>
                <span class="text-text1 heading-5" v-else>---</span>
              </p>
            </div>
            <div class="p-1">
              <p class="mb-0 items-center flex">
                <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:170px">SMTP Encryption</p></span>
                <span><p class="mb-0 dotwidth">:</p></span>
                <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="data.smtpEncryption !== ''">{{data.smtpEncryption}}</span>
                <span class="text-text1 heading-5" v-else>---</span>
              </p>
            </div>
            <div class="p-1">
              <p class="mb-0 items-center flex">
                <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:170px">SMTP Host</p></span>
                <span><p class="mb-0 dotwidth">:</p></span>
                <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="data.smtpHost !== ''">{{data.smtpHost}}</span>
                <span class="text-text1 heading-5" v-else>---</span>
              </p>
            </div>
            <div class="p-1">
              <p class="mb-0 items-center flex">
                <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:170px">SMTP Port</p></span>
                <span><p class="mb-0 dotwidth">:</p></span>
                <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="data.smtpHost !== ''">{{data.smtpPort}}</span>
                <span class="text-text1 heading-5" v-else>---</span>
              </p>
            </div>
          </div>
        </div>
        <div class="layout my-2" v-if="organizationList.length === 0">
        <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
          <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <span class="sr-only">Info</span>
          <div>
            <p class=" font-medium heading-4">No record found.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="openEditPopup" class="popup_overlay px-4">
      <div class="custom_dialog rounded-lg pb-4" style="width: 1000px;max-height: 95%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between items-center p-3">
              <div class=" font-medium text-white heading-3">
                Update 
              </div>
              <div>
                <span class=" text-text1 heading-6 font-semibold cursor-pointer bg-gray1 px-3 py-2 rounded-lg uppercase" @click="openEditPopup = false;resetError()">
                  Cancel
                </span>
                <span class=" text-white heading-6 ml-2 font-semibold cursor-pointer bg-success px-3 py-2 rounded-lg uppercase" @click="saveUpdate()">
                  Update
                </span>
              </div>
            </div>
            <div class="p-2">
              <div class="grid grid-cols-1 md:grid-cols-12 xl:grid-cols-12 2xl:grid-cols-12">
                <div class="box col-span-6 p-2">
                  <p class="label_css">Label</p>
                  <input
                  :class="errObj.labelErr !== '' ? 'border border-error' :'border border-gray-500 focus:border-primary'"
                  class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
                  placeholder="Label"
                  v-model="sendObject.emailLabel" @keypress="errObj.labelErr=''"
                  >
                  <p class="text-error" v-if="errObj.labelErr !== ''">{{errObj.labelErr}}</p>
                </div>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-12 xl:grid-cols-12 2xl:grid-cols-12">
                <div class="box col-span-6  p-2">
                  <p class="label_css">Email</p>
                  <input
                  :class="errObj.emailErr !== '' ? 'border border-error' :'border border-gray-500 focus:border-primary'"
                  class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
                  placeholder="Email"
                  v-model="sendObject.emailId" @keypress="errObj.emailErr=''"
                  >
                  <p class="text-error" v-if="errObj.emailErr !== ''">{{errObj.emailErr}}</p>
                </div>
                <div class="box col-span-6 p-2 relative">
                  <p class="label_css">Password</p>
                  <input :class="errObj.passwordErr !== '' ? 'border border-error' :'border border-gray-500 focus:border-primary'"  class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4"
                    v-model="sendObject.passwordEnc"
                    placeholder="Password"
                    :type="showPassword ? 'text' : 'password'"
                    @keypress="errObj.passwordErr=''"
                    @click:append="showPassword = !showPassword"
                  >
                  <span class="absolute inset-y-0 top-4 right-2 flex items-center pl-3">
                    <span class="p-1 pr-2 focus:outline-none focus:shadow-outline">
                      <span v-if="!showPassword" @click="showPassword = !showPassword" class="cursor-pointer"><img height="26px" width="26px" src="@/assets/images/svg/hide-pass.svg" alt="clearInput"></span>
                     <span v-else @click="showPassword = !showPassword" class="cursor-pointer"><img height="16px" width="24px" src="@/assets/images/svg/show-pass.svg" alt="clearInput"></span>
                    </span>
                  </span>
                  <p class="text-error" v-if="errObj.passwordErr !== ''">{{errObj.passwordErr}}</p>
                </div>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-12 xl:grid-cols-12 2xl:grid-cols-12">
                <div class="box col-span-3 p-2">
                  <p class="label_css">SMTP Encryption</p>
                  <input
                  :class="errObj.smtpEncErr !== '' ? 'border border-error' :'border border-gray-500 focus:border-primary'"
                  class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
                  placeholder="SMTP Encryption"
                  v-model="sendObject.smtpEncryption" @keypress="errObj.smtpEncErr=''"
                  >
                  <p class="text-error" v-if="errObj.smtpEncErr !== ''">{{errObj.smtpEncErr}}</p>
                </div>
                <div class="box col-span-5 p-2">
                  <p class="label_css">SMTP Host</p>
                  <input
                  :class="errObj.smtpHostErr !== '' ? 'border border-error' :'border border-gray-500 focus:border-primary'"
                  class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
                  placeholder="SMTP Host"
                  v-model="sendObject.smtpHost" @keypress="errObj.smtpHostErr=''"
                  >
                  <p class="text-error" v-if="errObj.smtpHostErr !== ''">{{errObj.smtpHostErr}}</p>
                </div>
                <div class="box col-span-4 p-2">
                  <p class="label_css">SMTP Port</p>
                  <input
                  :class="errObj.smtpPortErr !== '' ? 'border border-error' :'border border-gray-500 focus:border-primary'"
                  class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
                  placeholder="SMTP Port"
                  v-model="sendObject.smtpPort" @keypress="errObj.smtpPortErr=''"
                  >
                  <p class="text-error" v-if="errObj.smtpPortErr !== ''">{{errObj.smtpPortErr}}</p>
                </div>
              </div>
              <div class="p-2">
                <div class="flex items-center">
                  <label class="cu_switch cursor-pointer mr-3" :class="!sendObject.isActive ? 'ml-2' : ''">
                    <input type="checkbox" v-model="sendObject.isActive">
                    <span class="cu_slider cu_round"></span>
                  </label>
                  <p class="text-gray-900 pl-1">{{sendObject.isActive ? 'Active' : 'Inactive'}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
</template>
<script>
import { validateEmail} from "@/utils/validation.js";
import {getDecryptedPassword, getEncryptedPassword} from '@/utils/encrytPassword.js'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
  },
  mixins: [deboucneMixin],
  props: [],
  data () {
    return {
      showPassword: false,
      errObj: {
        labelErr: '',
        emailErr: '',
        passwordErr: '',
        smtpEncErr: '',
        smtpHostErr: '',
        smtpPortErr: '',
      },
      organizationList: [],
      openEditPopup: false,
      sendObject: {
        emailConfigId: 1,
        emailId: '',
        emailLabel: '',
        password: '',
        passwordEnc: '',
        smtpHost: '',
        smtpPort: '',
        smtpEncryption: '',
        isActive: true
      }
    }
  },
  created() {
  },
  mounted () {
    document.title = '@ Email Configuration'
    this.getEmailCOnfiguarionDetail()
  },
  watch: {
  },
  methods: {
    redirectToDetail (data) {
      this.sendObject.emailConfigId = data.emailConfigId
      this.sendObject.emailId = data.emailId
      this.sendObject.emailLabel = data.emailLabel
      this.sendObject.password = ''
      this.sendObject.passwordEnc = data.passwordEnc
      this.sendObject.smtpHost = data.smtpHost
      this.sendObject.smtpPort = data.smtpPort
      this.sendObject.smtpEncryption = data.smtpEncryption
      this.sendObject.isActive = data.isActive
      this.openEditPopup = true
    },
    saveUpdate () {
      let isError = false
      if (this.sendObject.emailLabel === '') {
        isError = true
        this.errObj.labelErr = 'Email Label is required'
      }
      if (this.sendObject.emailId === '') {
        isError = true
        this.errObj.emailErr = 'Email Label is required'
      }
      if (this.errObj.emailErr === '') {
        if (validateEmail(this.sendObject.emailId) === false) {
          this.errObj.emailErr = "Please enter valid email";
          isError = true
        }
      }
      if (this.sendObject.passwordEnc === '') {
        isError = true
        this.errObj.passwordErr = 'Password is required'
      }
      if (this.sendObject.smtpEncryption === '') {
        isError = true
        this.errObj.smtpEncErr = 'SMTP Encryption is required'
      }
      if (this.sendObject.smtpHost === '') {
        isError = true
        this.errObj.smtpHostErr = 'SMTP Host is required'
      }
      if (this.sendObject.smtpPort === '') {
        isError = true
        this.errObj.smtpPortErr = 'SMTP Port is required'
      }
      console.log('isError', isError)
      if (!isError) {
        this.sendObject.password = getEncryptedPassword(this.sendObject.passwordEnc)
        console.log('this.sendObject', this.sendObject)
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        ADMINAPI.UpdateEmailConfiguration(
          this.sendObject,
          response => {
            this.resetError()
            this.getEmailCOnfiguarionDetail()
            this.organizationList = []
            this.openEditPopup = false
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
      
    },
    resetError () {
      this.errObj.labelErr = ''
      this.errObj.emailErr = ''
      this.errObj.passwordErr = ''
      this.errObj.smtpEncErr = ''
      this.errObj.smtpHostErr = ''
      this.errObj.smtpPortErr = ''
    },
    getEmailCOnfiguarionDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetEmailConfDetail(
        response => {
          let temp = response.Data !== null ? response.Data : []
          for (let index = 0; index < temp.length; index++) {
            temp[index].passwordEnc = getDecryptedPassword(temp[index].password)
          }
          this.organizationList = temp
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}

.dotwidth {
  width: 15px;
}
</style>
